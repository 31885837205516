/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ObservableSummaryItem = {
    properties: {
        account_id: {
            type: 'string',
            format: 'uuid',
        },
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        date_of: {
            type: 'string',
            format: 'date',
        },
        count: {
            type: 'number',
        },
        distinct_identifiers: {
            type: 'number',
        },
        total_value: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }, {
                type: 'number',
            }],
        },
        avg_value: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }, {
                type: 'number',
            }],
        },
        src_label: {
            type: 'string',
        },
        workflow_ulid: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
    },
} as const;
