import {parse as uuidParse } from "uuid"

const DECODING = new Uint8Array([
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0x00, 0x01,
    0x02, 0x03, 0x04, 0x05, 0x06, 0x07, 0x08, 0x09, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0x0A, 0x0B, 0x0C, 0x0D, 0x0E,
    0x0F, 0x10, 0x11, 0x01, 0x12, 0x13, 0x01, 0x14, 0x15, 0x00,
    0x16, 0x17, 0x18, 0x19, 0x1A, 0xFF, 0x1B, 0x1C, 0x1D, 0x1E,
    0x1F, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0x0A, 0x0B, 0x0C,
    0x0D, 0x0E, 0x0F, 0x10, 0x11, 0x01, 0x12, 0x13, 0x01, 0x14,
    0x15, 0x00, 0x16, 0x17, 0x18, 0x19, 0x1A, 0xFF, 0x1B, 0x1C,
    0x1D, 0x1E, 0x1F, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF
])


export function to_uuid(ulid:string):string {

    // Convert the ULID to a byte array
    const encoder = new TextEncoder();
    const encoded  = encoder.encode(ulid);
    const bytes= new Uint8Array([
        ((DECODING[encoded[0]] << 5) | DECODING[encoded[1]]) & 0xFF,
        ((DECODING[encoded[2]] << 3) | (DECODING[encoded[3]] >> 2)) & 0xFF,
        ((DECODING[encoded[3]] << 6) | (DECODING[encoded[4]] << 1) | (DECODING[encoded[5]] >> 4)) & 0xFF,
        ((DECODING[encoded[5]] << 4) | (DECODING[encoded[6]] >> 1)) & 0xFF,
        ((DECODING[encoded[6]] << 7) | (DECODING[encoded[7]] << 2) | (DECODING[encoded[8]] >> 3)) & 0xFF,
        ((DECODING[encoded[8]] << 5) | (DECODING[encoded[9]])) & 0xFF,
        ((DECODING[encoded[10]] << 3) | (DECODING[encoded[11]] >> 2)) & 0xFF,
        ((DECODING[encoded[11]] << 6) | (DECODING[encoded[12]] << 1) | (DECODING[encoded[13]] >> 4)) & 0xFF,
        ((DECODING[encoded[13]] << 4) | (DECODING[encoded[14]] >> 1)) & 0xFF,
        ((DECODING[encoded[14]] << 7) | (DECODING[encoded[15]] << 2) | (DECODING[encoded[16]] >> 3)) & 0xFF,
        ((DECODING[encoded[16]] << 5) | (DECODING[encoded[17]])) & 0xFF,
        ((DECODING[encoded[18]] << 3) | (DECODING[encoded[19]] >> 2)) & 0xFF,
        ((DECODING[encoded[19]] << 6) | (DECODING[encoded[20]] << 1) | (DECODING[encoded[21]] >> 4)) & 0xFF,
        ((DECODING[encoded[21]] << 4) | (DECODING[encoded[22]] >> 1)) & 0xFF,
        ((DECODING[encoded[22]] << 7) | (DECODING[encoded[23]] << 2) | (DECODING[encoded[24]] >> 3)) & 0xFF,
        ((DECODING[encoded[24]] << 5) | (DECODING[encoded[25]])) & 0xFF
    ])

    return [...bytes].map(b => b.toString(16).padStart(2,'0'))
        .join('')
        .replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5')
        .toString()
}

const RX_ULID = /^[0-7][0-9A-HJKMNP-TV-Z]{25}$/
const ULID_CHARS = "0123456789ABCDEFGHJKMNPQRSTVWXYZ"
export function is_ulid(maybe:string):boolean {
    return RX_ULID.test(maybe) 
}

export function uuid_to_bytes(uuid:string){
  var v;
  var arr = new Uint8Array(16); // Parse ########-....-....-....-............

  arr[0] = (v = parseInt(uuid.slice(0, 8), 16)) >>> 24;
  arr[1] = v >>> 16 & 0xff;
  arr[2] = v >>> 8 & 0xff;
  arr[3] = v & 0xff; // Parse ........-####-....-....-............

  arr[4] = (v = parseInt(uuid.slice(9, 13), 16)) >>> 8;
  arr[5] = v & 0xff; // Parse ........-....-####-....-............

  arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
  arr[7] = v & 0xff; // Parse ........-....-....-####-............

  arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
  arr[9] = v & 0xff; // Parse ........-....-....-....-############
  // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)

  arr[10] = (v = parseInt(uuid.slice(24, 36), 16)) / 0x10000000000 & 0xff;
  arr[11] = v / 0x100000000 & 0xff;
  arr[12] = v >>> 24 & 0xff;
  arr[13] = v >>> 16 & 0xff;
  arr[14] = v >>> 8 & 0xff;
  arr[15] = v & 0xff;
  return arr;
}

export function from_uuid(uuid:string):string {
    let data = uuid_to_bytes(uuid);
    return   ULID_CHARS[(data[0] & 224) >> 5] + 
        ULID_CHARS[data[0] & 31] + 
        ULID_CHARS[(data[1] & 248) >> 3] + 
        ULID_CHARS[((data[1] & 7) << 2) | ((data[2] & 192) >> 6)] + 
        ULID_CHARS[((data[2] & 62) >> 1)] + 
        ULID_CHARS[((data[2] & 1) << 4) | ((data[3] & 240) >> 4)] + 
        ULID_CHARS[((data[3] & 15) << 1) | ((data[4] & 128) >> 7)] + 
        ULID_CHARS[(data[4] & 124) >> 2] + 
        ULID_CHARS[((data[4] & 3) << 3) | ((data[5] & 224) >> 5)] + 
        ULID_CHARS[data[5] & 31] + 
        ULID_CHARS[(data[6] & 248) >> 3] + 
        ULID_CHARS[((data[6] & 7) << 2) | ((data[7] & 192) >> 6)] + 
        ULID_CHARS[(data[7] & 62) >> 1] + 
        ULID_CHARS[((data[7] & 1) << 4) | ((data[8] & 240) >> 4)] + 
        ULID_CHARS[((data[8] & 15) << 1) | ((data[9] & 128) >> 7)] + 
        ULID_CHARS[(data[9] & 124) >> 2] + 
        ULID_CHARS[((data[9] & 3) << 3) | ((data[10] & 224) >> 5)] + 
        ULID_CHARS[data[10] & 31] + 
        ULID_CHARS[(data[11] & 248) >> 3] + 
        ULID_CHARS[((data[11] & 7) << 2) | ((data[12] & 192) >> 6)] + 
        ULID_CHARS[(data[12] & 62) >> 1] + 
        ULID_CHARS[((data[12] & 1) << 4) | ((data[13] & 240) >> 4)] + 
        ULID_CHARS[((data[13] & 15) << 1) | ((data[14] & 128) >> 7)] + 
        ULID_CHARS[(data[14] & 124) >> 2] + 
        ULID_CHARS[((data[14] & 3) << 3) | ((data[15] & 224) >> 5)] + 
        ULID_CHARS[data[15] & 31]
}



